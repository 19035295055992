<template>
    <div>
        <div class="table-control">
            <div class="zoom">
                <div
                    class="zoom-in-icon"
                    :class="{ disabled: modelTableScaleRatio === 1 }"
                    tooltip="放大表格"
                    @click="zoom('in')"
                />
                <div
                    class="zoom-out-icon"
                    :class="{ disabled: modelTableScaleRatio <= tableScale }"
                    tooltip="縮小表格"
                    @click="zoom('out')"
                />
            </div>
            <div class="toolbox-container">
                <div
                    class="x-cancel-icon"
                    tooltip="離開全螢幕表格"
                    @click="closeHandler()"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Zooming',
    emits: ['update:modelIsFullSize', 'update:modelTableScaleRatio'],
    props: {
        modelIsFullSize: {
            type: Boolean,
            default: false
        },
        modelTableScaleRatio: {
            type: Number,
            default: 1
        }
    },
    methods: {
        zoom: function (zoom) {
            let scale = this.modelTableScaleRatio
            if (zoom === 'in') {
                if (scale < 1) {
                    scale = scale + 0.05
                } else {
                    scale = 1
                }
            } else {
                if (this.tableScale < scale) {
                    scale = scale - 0.05
                } else {
                    scale = this.tableScale
                }
            }
            this.$setGaEvent(`zoom${zoom}`, 'fullSize-Tool')
            this.$emit('update:modelTableScaleRatio', scale)
        },
        openFullscreen: function () {
            let elem = document.documentElement
            if (elem.requestFullscreen) {
                elem.requestFullscreen()
            } else if (elem.webkitRequestFullscreen) {
                /* Safari */
                elem.webkitRequestFullscreen()
            } else if (elem.msRequestFullscreen) {
                /* IE11 */
                elem.msRequestFullscreen()
            }
        },
        closeFullscreen: function () {
            if (!this.modelIsFullSize) return

            this.$emit('update:modelIsFullSize', false)

            if (document.fullscreenElement) {
                document.exitFullscreen()
            } else if (document.webkitExitFullscreen) {
                /* Safari */
                document.webkitExitFullscreen()
            } else if (document.msExitFullscreen) {
                /* IE11 */
                document.msExitFullscreen()
            }
        },
        getTableScale: function () {
            this.$nextTick(() => {
                let windowWidth = this.isMobileSize
                    ? this.$getDocumentWidth() - 30
                    : this.$getDocumentWidth() - 50

                let tableWidth = document.querySelector('.table > .header')
                    .clientWidth
                this.tableScale = windowWidth / tableWidth
                this.$emit('update:modelTableScaleRatio', this.tableScale)
            })
        },
        closeHandler: function () {
            this.closeFullscreen()
            this.$setGaEvent(`closeFullSize`, 'fullSize-Tool')
        }
    },
    computed: {
        isMobileSize: function () {
            if (this.$getDocumentWidth() < 900 && this.$getHeight() < 576) {
                return true
            } else if (
                this.$getDocumentWidth() < 576 &&
                this.$getHeight() < 900
            ) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        modelIsFullSize: function () {
            if (this.modelIsFullSize) {
                this.openFullscreen()
                this.getTableScale()
            }
        },
        isMobileSize: function () {
            this.getTableScale()
        },
        $getHeight: function () {
            this.getTableScale()
        },
        '$route.path': {
            handler() {
                this.closeFullscreen()
            },
            deep: true
        }
    },
    data() {
        return {
            tableScale: 1
        }
    }
}
</script>

<style lang="scss" scoped>
.table-control {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 8;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    background: $primary-grey;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    .zoom {
        display: flex;
        margin-right: 20px;
        .zoom-out-icon,
        .zoom-in-icon {
            width: 32px;
            height: 32px;
            background-color: $primary-white;
            border-radius: 4px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

            &:first-of-type {
                margin-right: 20px;
            }
        }
    }
    .toolbox-container {
        display: flex;
        align-items: center;
        border-left: $hr-grey 1px solid;
        height: 32px;
        margin-right: 20px;

        .x-cancel-icon {
            width: 18px;
            height: 18px;
            margin-left: 15px;
            cursor: pointer;
            @media screen and (min-width: 0px) and (max-width: 576px) {
                margin-left: 10px;
            }
        }
    }

    @media screen and (min-width: 0px) and (max-width: 576px) {
        justify-content: flex-end;
        width: 100%;
    }
}
</style>
